import styled from 'styled-components';
export const ComponentContainer = styled.div`
	max-width: 1440px;
	margin: 50px auto;
	padding: ${(props) => props.theme.globalPaddingMobile};

	@media (min-width: 768px) {
		padding:0 30px;
	}

	@media (min-width: 1280px) {
		padding: ${(props) => props.theme.globalPaddingDesktop};
	}
`;

export const OverflowContainer = styled.div`overflow: hidden;`;

export const SubText = styled.div`
	/* display: flex; */
	/* flex-direction: column; */
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 29px;
	z-index: 14;
	grid-template-areas: "left" "right";

	@media (min-width: 768px) {
		/* flex-direction: row; */
		grid-template-columns: 1fr 1fr;
		grid-gap: 55px;
		grid-template-areas: 'left right';
	}

	@media (min-width: 1024px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 55px;
		grid-template-areas: 'left . right';
	}

	> *:last-child {
		grid-area: left;

		@media (min-width: 768px) {
			grid-area: right;
		}
	}

	h4 {
		font-family: 'Freight Big';
		font-size: 24px;
		text-transform: none;
		margin-bottom: 0;
		grid-area: right;

		@media (min-width: 768px) {
			width: 200px;
			font-size: 32px;
			margin-right: 20px;
			grid-area: left;
		}

		@media (min-width: 1024px) {
			width: 310px;
			font-size: 48px;
			margin-right: 110px;
		}
	}
`;

export const GridWrapper = styled.div`
	padding-top: 30px;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 10px;
	grid-auto-flow: row;
	justify-content: center;
	padding-bottom: 20px;

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
		padding-top: 38px;
		grid-gap: 30px;
		padding-bottom: 40px;
	}

	@media (min-width: 1280px) {
		grid-gap: 55px;
	}
`;

export const GridWrapperSmall = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 10px;

	position: relative;
	padding-bottom: 30px;

	> div:nth-of-type(6) {
		> div {
			::before {
				display: none;
			}
			::after {
				bottom: -29px;
			}
		}
	}

	::before {
		content: "";
		position: absolute;
		bottom: 0;
		margin: 0;
		background: black;
		height: 1px;
		width: 100%;
		display: block;

		@media (min-width: 768px) {
			display: none;
		}
	}

	@media (min-width: 480px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
	}

	@media (min-width: 768px) {
		padding-top: 30px;
		grid-gap: 30px;

		> div:nth-of-type(5) {
			> div {
				::before {
					display: none;
				}
				::after {
					bottom: -29px;
				}
			}
		}

		> div:nth-of-type(5) {
			> div {
				::before {
					display: block;
					width: 500%;

					bottom: -29px;
				}
			}
		}
	}

	@media (min-width: 1280px) {
		grid-gap: 40px;
		padding-top: 40px;
	}
`;

export const FullWidthWrapper = styled.div`
	padding-top: 20px;

	@media (min-width: 768px) {
		padding-top: 50px;
	}

	@media (min-width: 1280px) {
		padding-top: 80px;
	}
`;
