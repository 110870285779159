import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import { NewsListing } from '../components/NewsListing'

const Stories = ({ data }) => {
  // console.log(data)

  return (
    <Layout headerTitle="Stories" generic>
      <SEO
        title={data.page.seo.title}
        description={data.page.seo.metaDesc}
        image={data.page.avContent.featureImg?.sourceUrl}
      />

      {/* <NewsListing posts={data.allWordpressPost.edges.slice(0, 2)} /> */}
      <NewsListing  />
    </Layout>
  )
}

export default Stories

// Set here the ID of the home page.
export const pageQuery = graphql`
  query($id: String!) {
    

    page:wpPage(id: { eq: $id }) {
			title
			seo {
				title
				metaDesc
      }

      avContent {
        featureImage {
          srcSet
          sourceUrl
        }
      }
    }
  }
`
