import React, { Component } from 'react'
import AlgoliaIndex from '../../utils/algoliaIndex'
import AlgoliaFilters from '../../utils/algoliaFilters'

import { Teaser } from '../Teaser'
import { SectionHeader } from '../SectionHeader'
import { MoreResults } from '../MoreResults'
import SelectComponent from '../Select/selectComponent'
import slugify from 'slugify'

import {
  ComponentContainer,
  OverflowContainer,
  GridWrapper,
  SubText,
  GridWrapperSmall,
  FullWidthWrapper,
} from './style.js'

class WhatsOn extends Component {
  constructor(props) {
    super(props)

    // Change filter, label and value if this is an Industry Hub blog
    let bIndustryHub = props.industryHub
    const typeFilter = bIndustryHub ? 'Industry Hub' : '-Industry Hub'
    const typeValue = bIndustryHub ? 'Industry Hub' : 'Stories'
    const typeLabel = 'All'

    this.algoliaIndex = new AlgoliaIndex({
      types: ['Stories', typeFilter],
      hitsPerPage: 7,
      pageOneExtraHits: 4, //Extra results for first "page"
    })

    this.algoliaFilters = new AlgoliaFilters({
      types: ['Stories', typeFilter],
    })

    this.state = {
      bIndustryHub: bIndustryHub,
      blogTypeFilter: typeFilter,
      featuredItems: [],
      newsItems: [],
      currentPage: 0,
      moreResults: false,
      categories: [{ value: typeValue, label: typeLabel }],
      filter: { value: typeValue, label: typeLabel },
    }
  }

  componentWillReceiveProps() {
    const urlParams = new URLSearchParams(window.location.search)
    const filter = urlParams.get('c')

    if (filter) {
      this.categoryFilter(filter)
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const filter = urlParams.get('c')

    if (filter) {
      this.setState({ filter })
      console.log(filter)
    } else {
      this.search()
    }

    this.categoryFilter(filter)
  }

  categoryFilter(filter) {
    this.algoliaFilters.getCategories((err, categories) => {
      if (categories) {
        let filteredcats = categories.filter(cat => {
          if (cat.value === 'Stories') {
            if (this.state.bIndustryHub) {
              return false
            }
            cat.label = 'All'
          }

          if (cat.value === 'Industry Hub') {
            cat.label = 'All'
          }
          cat.value = slugify(cat.value, {
            lower: true,
            remove: /[*+~.()&'"!:@]/g,
          })
          return cat
        })

        filteredcats.map(category => {
          if (filter === category.value) {
            this.setState({
              filter: { value: category.value, label: category.label },
            })
            this.algoliaIndex.updateFilters(['Stories', category.label])
            this.search()
          }
        })

        this.setState({ categories: filteredcats })
      }
    })
  }

  search() {
    this.algoliaIndex.search(0, (err, results) => {
      if (err) {
        return console.log(err)
      }

      let { hits, length, offset, nbHits } = results.results[0]

      let initalResults = [...hits]
      let featuredItems = [...initalResults.splice(0, 4)]
      let newsItems = [...initalResults]

      this.setState({
        newsItems,
        featuredItems,
        currentPage: 0,
        moreResults: offset + length < nbHits,
      })
    })
  }

  moreResults() {
    let thisPage = this.state.currentPage + 1
    this.algoliaIndex.search(thisPage, (err, results) => {
      if (err) {
        return console.log(err)
      }

      let { length, offset, nbHits } = results.results[0]

      this.setState({
        newsItems: [...this.state.newsItems, ...results.results[0].hits],
        currentPage: thisPage,
        moreResults: offset + length < nbHits,
      })
    })
  }

  updateFilter(filter) {
    this.setState({ filter })

    let filterType = filter.label

    // convert to if statements
    if (filter.value === 'stories') {
      filterType = 'Stories'
    }

    if (filter.value === 'industry-hub') {
      filterType = 'Industry Hub'
    }

    this.algoliaIndex.updateFilters([
      'Stories',
      this.state.blogTypeFilter,
      filterType,
    ])

    console.log('yep', filter)
    this.search()
  }

  rowRender(stateItems) {
    let arrays = [],
      largeRow = false
    let items = [...stateItems] //avoid altering state

    while (items.length > 0) {
      arrays.push(items.splice(0, largeRow ? 1 : 6))
      largeRow = !largeRow
    }

    return arrays.map((row, idx) => {
      let rowkey = row.reduce((prev, curr) => {
        return `${prev}${curr.post_id}`
      })

      if (idx % 2 === 0) {
        //even row, render 6, odd render large row
        return (
          <GridWrapperSmall key={rowkey}>
            {row.map(smallItem => {
              return (
                <Teaser
                  key={smallItem.post_id}
                  story
                  openInNewTab
                  categories={smallItem.type}
                  img={{
                    ...smallItem.img,
                    sizes: '300px',
                  }}
                  title={smallItem.post_title}
                  url={smallItem.permalink}
                  date={[smallItem.post_date]}
                />
              )
            })}
          </GridWrapperSmall>
        )
      } else {
        return (
          <FullWidthWrapper key={row[0].post_id}>
            <Teaser
              fullWidth
              storyFullWidth
              openInNewTab
              categories={row[0].type}
              img={{
                ...row[0].img,
                sizes: '500px',
              }}
              title={row[0].post_title}
              url={row[0].permalink}
              desc={row[0].excerpt}
              date={[row[0].post_date]}
            />
          </FullWidthWrapper>
        )
      }
    })
  }
  render() {
    return (
      <ComponentContainer>
        <SubText>
          <h4>Featured</h4>
          <SelectComponent
            stories
            value={this.state.filter}
            options={this.state.categories}
            placeholder="Category"
            onChange={this.updateFilter.bind(this)}
            className="stories"
          />
        </SubText>

        <OverflowContainer>
          <GridWrapper>
            {this.state.featuredItems.length
              ? this.state.featuredItems.map(post => {
                  return (
                    <Teaser
                      key={`FI-${post.post_id}`}
                      storyMain
                      lineD
                      openInNewTab
                      categories={post.type}
                      img={{
                        ...post.img,
                        sizes: '500px',
                      }}
                      title={post.post_title}
                      desc={post.excerpt}
                      url={post.permalink}
                      date={[post.post_date]}
                    />
                  )
                })
              : null}
          </GridWrapper>
          {this.state.newsItems.length > 0 && (
            <SectionHeader smallBold title="more stories" />
          )}

          {this.rowRender(this.state.newsItems)}
          {this.state.moreResults && (
            <MoreResults
              moreResults={this.moreResults.bind(this)}
              label="stories"
            />
          )}
        </OverflowContainer>
      </ComponentContainer>
    )
  }
}

export default WhatsOn
